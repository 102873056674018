import React from 'react';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { Button } from '@radix-ui/themes';

const App = () => {
  return (
    <div className="font-serif">
      {/* Hero Section */}
      <div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: "url('/assets/hero2.png')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-25"></div>
        <div className="relative z-10 h-full flex flex-col justify-between">
          <div className="p-6">
            <img src="/assets/logo_serif_white.png" alt="Logo" width="200px" />
          </div>
          <div className="flex flex-col items-end justify-center text-right pr-10 pb-52 text-white" style={{ marginTop: '-10vh' }}>
            <div className="p-8 text-right parallax" id="parallax-text">
              <h1 className="text-5xl font-serif font-bold mb-4">
                L'essentiel de l'actualité crypto,<br /> sans se presser, le temps d'un café.
              </h1>
              <p className="text-lg mb-6 font-serif italic">
                La pause crypto qui éclaire vos journées.
              </p>
              <div className="space-x-4">
                <a
                  href="/login"
                  className="px-6 py-2 bg-gray-800 text-white rounded shadow hover:bg-gray-600 transition"
                >
                  Se connecter
                </a>
                <a
                  href="/register"
                  className="px-6 py-2 bg-gray-800 text-white rounded shadow hover:bg-gray-600 transition"
                >
                  S'inscrire
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="container mx-auto px-6 py-12 space-y-12 font-serif bg-white text-gray-800">
        {/* Section 1: Vue d’ensemble */}
        <div className="pb-12 border-b">
          <h2 className="text-3xl font-bold text-center mb-8">
            Un seul outil pour avoir une vue d’ensemble ! 📰
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {['Intelligence Artificielle', 'Actualités', 'Réseaux sociaux'].map((title, index) => (
              <div key={index} className="text-center">
                <h3 className="text-xl font-semibold mb-4">{title}</h3>
                <p>
                  {title === 'Intelligence Artificielle'
                    ? "Nous utilisons des systèmes experts pour résumer les articles de presse, traiter de grandes quantités de données financières et vous fournir les meilleures informations."
                    : title === 'Actualités'
                    ? "Nous sommes en mesure de vous offrir un concentré d’actualités grâce à nos partenaires et à notre algorithme."
                    : "Nous analysons les posts crypto des réseaux sociaux pour vous fournir le ressenti principal des utilisateurs."}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Section 2: Notre application */}
        <div className="mt-5">
          <h2 className="text-3xl font-bold text-center mb-8">Notre application</h2>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            <img src="/assets/logo_app.png" alt="Koffy App Logo" className="w-32 h-32 object-cover" />
            <div>
              <p className="text-center md:text-left mb-4">
                Téléchargez Koffy dès maintenant sur votre téléphone pour bénéficier des notifications<br /> et d'une expérience encore meilleure !
              </p>
              <div className="flex justify-center md:justify-start gap-4">
                <a
                  href="https://apps.apple.com/fr/app/koffy/id6474247267"
                  className="px-6 py-2 bg-gray-800 text-white rounded shadow hover:bg-gray-600 transition"
                >
                  App Store
                </a>
                <a
                  href="http://embedded.koffy.finance/koffy-v6.apk"
                  className="px-6 py-2 bg-gray-800 text-white rounded shadow hover:bg-gray-600 transition"
                >
                  Télécharger APK
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Section: Media Partners */}
        <div className="mt-12 py-12 border-t">
          <h2 className="text-3xl font-bold text-center mb-8">Nos médias partenaires</h2>
          <p className="text-center mb-8 max-w-2xl mx-auto text-gray-600">
            Nous collaborons avec les plus grandes entreprises médiatiques du secteur, sous contrat ou accord exclusif. Découvrez nos partenaires qui nous permettent de vous offrir une actualité crypto complète et fiable.
          </p>
          <div className="relative overflow-hidden" style={{ 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          <ScrollArea>
              <div className="flex items-center gap-8">
                {[
                    "cryptoast-logo-amp.webp",
                    "logo_aucoindubloc.svg",
                    "logo_beincrypto.svg",
                    "logo_coinacademy.svg",
                    "logo_coinactucrypto.png",
                    "logo_coinaute.png"
                ]
                .map((logo, i) => (
                  <img
                    key={i}
                    src={`/imgs/partners/${logo}`}
                    alt={`Partenaire ${i + 1}`}
                    className="w-28 h-28 object-contain"
                  />
                ))}
              </div>
            </ScrollArea>
          </div>
          <div className="relative overflow-hidden" style={{ 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
            <ScrollArea>
              <div className="flex items-center gap-8">
                {[
                    "logo_cryptonomist.svg",
                    "logo_ct.svg",
                    "logo_cte.svg",
                    "logo_jdc.svg",
                    "logo_onchain.svg"
                ]
                .map((logo, i) => (
                  <img
                    key={i}
                    src={`/imgs/partners/${logo}`}
                    alt={`Partenaire ${i + 1}`}
                    className="w-28 h-28 object-contain"
                  />
                ))}
              </div>
            </ScrollArea>
          </div>
        </div>

        {/* Section: Qu’y a-t-il dans mon Koffy ? */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center py-12">
          <div>
            <h2 className="text-3xl font-bold mb-4">Qu’y a-t-il dans mon Koffy ?</h2>
            <p>
              Chaque jour, retrouvez un résumé de l’actualité, l’état des marchés, une analyse sentimentale des réseaux sociaux, offres d’emploi et tendances NFT ainsi qu'un podcast reprenant les informations de l'édition. Nous améliorons constamment Koffy en ajoutant de nouvelles fonctionnalités, que vous (les lecteurs), nous suggérez !
            </p>
          </div>
          <img
            src="/assets/ss_macbook_01.png"
            alt="Koffy Illustration"
            className="rounded-lg shadow-lg w-full h-auto object-cover"
          />
        </div>

        {/* Section: Carousel of Features */}
        <div className="relative overflow-hidden">
          <ScrollArea>
            <div className="mt-10 mb-10 flex gap-6 items-center">
              {['📰 Résumé de l\'actualité', '⏳ Time Machine', '🎙️ Espresso, votre podcast quotidien', '🔄 Flux d\'actualité en direct'].map((title, i) => (
                <div key={i} className="glass p-6 w-80 h-60 flex-shrink-0" style={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    borderRadius: '1rem',
                }}>
                  <h3 className="text-xl font-bold mb-2">{title}</h3>
                  <p className="text-gray-800">
                    {title === '📰 Résumé de l\'actualité'
                      ? "Un résumé quotidien des actualités crypto les plus importantes, livré directement dans votre Koffy."
                      : title === '⏳ Time Machine'
                      ? "Explorez plus de 3 ans d'actualités : une date, une édition, un Koffy."
                      : title === '🎙️ Espresso, votre podcast quotidien'
                      ? "Pour ceux qui ne peuvent lire l'édition, celle-ci est transformée en podcast grâce à notre IA, et ce : tous les jours."
                      : "Pour ceux qui ne peuvent se sustenter avec un Koffy par jour, nous disposons d'un flux d'actu en direct actualisé toutes les 10 minutes !"}
                  </p>
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 items-center py-12 border-b">
            <img src="assets/ss_macbook_02.png" alt="My KoFeed" class="rounded-lg shadow-lg w-full h-auto object-cover" />
            <div>
            <h2 class="text-3xl font-bold mb-4">
                Vos besoins, nos actualités :
            </h2>
            <h2 class="text-3xl font-bold mb-4">
                <span class="italic">My KoFeed</span>
            </h2>
            <p>En plus des trois à six articles d’actualité résumés par notre Intelligence Artificielle, nous vous
                permettons de définir jusqu’à 10 mots clés dans vos paramètres, qui seront utilisés pour vous montrer les
                titres d’actualité pertinents (et un lien vers le partenaire média correspondant) afin d’améliorer la
                surveillance des médias par rapport à vos intérêts !</p>
            </div>
      </div>

        {/* Section: Pricing */}
        <div className="py-12 border-b">
          <h2 className="text-3xl font-bold text-center mb-12">Tarifs</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12">

            <div className="text-center">
                <h3 className="font-bold text-xl mb-4">Mensuel</h3>
                <div className={`p-6 bg-white bg-opacity-50 backdrop-blur-lg rounded-lg shadow-lg`}>
                  <p className="text-2xl font-bold">
                    2€/mois
                  </p>
                  <p>
                  Toutes les fonctionnalités, pour le prix d’une tasse de café, par mois !
                  </p>
                </div>
            </div>
            <div className="text-center">
                <h3 className="font-bold text-xl mb-4">Annuel</h3>
                <div className={`p-6 bg-gradient-to-br from-gray-300 to-gray-600 text-white  backdrop-blur-lg rounded-lg shadow-lg`}>
                  <p className="text-2xl font-bold">
                    20€/an
                  </p>
                  <p>
                  Toutes les fonctionnalités de Koffy, pour moins cher !
                  </p>
                </div>
            </div>
            <div className="text-center">
                <h3 className="font-bold text-xl mb-4">À vie</h3>
                <div className={`p-6 bg-white bg-opacity-50 backdrop-blur-lg rounded-lg shadow-lg`}>
                  <p className="text-2xl font-bold">
                    200€ une fois
                  </p>
                  <p>
                  La meilleure façon de nous soutenir ! Inclut toutes les fonctionnalités de Koffy.
                  </p>
                </div>
            </div>
            <div className="text-center">
                <h3 className="font-bold text-xl mb-4">Télégram</h3>
                <div className={`p-6 bg-white bg-opacity-50 backdrop-blur-lg rounded-lg shadow-lg`}>
                  <p className="text-2xl font-bold">
                    49€/mois
                  </p>
                  <p>
                  Toutes les fonctionnalités de l'abonnement classique. L'édition quotidienne de Koffy sur votre canal Telegram en plus !
                  </p>
                </div>
            </div>



          </div>
        </div>

        {/* Section: Solutions B2B */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center py-12">
          <div>
            <h2 className="text-3xl font-bold mb-4">Découvrez nos solutions B2B !</h2>
            <ul className="list-disc pl-6 space-y-4">
              <li>Médias internes et surveillance du marché</li>
              <li>Intégrez des actualités et des données à votre site web via notre API</li>
              <li>Intégrez toute l’édition Koffy dans votre espace client</li>
            </ul>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-5 gap-6">
            {['alyra.svg', 'logo-montaigne-patrimoine.webp', 'coinstancy.png', 'letzblock.png', 'logo_ks.png', 'logo_ambro.webp', 'web3-consult.png', 'crypto-expert.png'].map((logo, i) => (
              <div key={i} className="flex justify-center items-center">
                <img
                  src={`/assets/logos/cust/${logo}`}
                  alt={`Logo ${i + 1}`}
                  className="w-24 h-24 object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section: Koffy dans les médias*/}
      <div id="media" class="py-16 border-t">
        <h2 class="text-4xl font-serif font-bold text-center mb-10">📢 Koffy dans les médias</h2>
        <p class="text-center mb-12 max-w-2xl mx-auto text-gray-700 font-serif italic">
            Koffy est devenu un acteur incontournable de la veille crypto. Nous sommes régulièrement cités et interviewés, et nous apportons notre expertise dans des médias nationaux de premier plan.
        </p>

        {/* Section 1: Ils parlent de nous*/}
        <div class="mb-16">
            <h3 class="text-3xl font-serif font-bold text-center mb-8">🎙️ Ils parlent de nous</h3>
            <p class="text-center mb-6 max-w-xl mx-auto text-gray-600 font-serif">
                Les médias nous interviewent et parlent de Koffy comme une référence dans l'analyse et la veille crypto.
            </p>

            <div class="grid grid-cols-2 md:grid-cols-3 gap-10 items-center max-w-5xl mx-auto">
                {/* Logo 1*/}
                <a href="https://journalducoin.com/economie/koffy-finance-actu-crypto/" target="_blank" class="block opacity-80 hover:opacity-100 transition">
                    <img src="/assets/logos/partners/logo_jdc.svg" alt="Journal du coin" class="mx-auto w-24 h-auto" />
                </a>
                {/* Logo 2*/}
                <a href="https://www.youtube.com/watch?v=O_mFc3VLr9c" target="_blank" class="block opacity-80 hover:opacity-100 transition">
                    <img src="/assets/logos/cust/6Wpz_yRt_400x400.jpg" alt="Média 2" class="mx-auto w-24 h-auto" />
                </a>
                {/* Logo 3*/}
                <a href="https://aucoindubloc.com/koffy-agregateur-actualite-crypto-web3/" target="_blank" class="block opacity-80 hover:opacity-100 transition">
                    <img src="/assets/logos/partners/logo_aucoindubloc.svg" alt="Média 3" class="mx-auto w-32 h-auto" />
                </a>
            </div>
        </div>

        {/* Section: Koffy intervient dans les médias + Podcast*/}
        <div class="max-w-6xl mx-auto py-16 border-t">
          <div class="flex flex-col md:flex-row items-center justify-between gap-12">
              
              {/* Colonne 1: Nous intervenons*/}
              <div class="py-12 px-8 flex-1">
                  <h3 class="text-3xl font-serif font-bold text-center mb-6">📝 Nous intervenons dans les médias</h3>
                  <p class="text-center mb-6 text-gray-700 font-serif">
                    En tant que partenaire média, Koffy apporte son expertise crypto à travers des analyses exclusives et des interventions régulières.
                  </p>

                  <div class="grid grid-cols-2 gap-6 items-center max-w-xs mx-auto">
                      {/* Logo 1*/}
                      <a href="https://croonerradio.fr" target="_blank" class="block opacity-80 hover:opacity-100 transition">
                          <img src="/assets/logos/cust/croonerradio.webp" alt="CroonerRadio" class="mx-auto w-24 h-auto" />
                      </a>
                      {/* Logo 2*/}
                      <a href="https://echo.aimonino.com" target="_blank" class="block opacity-80 hover:opacity-100 transition">
                          <img src="/assets/logos/cust/echo.png" alt="Echo" class="mx-auto w-24 h-auto" />
                      </a>
                  </div>
              </div>

              {/* Séparateur vertical, bien aligné*/}
              <div class="hidden md:flex items-center">
                  <div class="w-px h-full bg-gray-300"></div>
              </div>

              {/* Colonne 2: Podcast*/}
              <div class="flex-1 text-center">
                  <h3 class="text-3xl font-serif font-bold text-center mb-6">🎧 Écoutez notre dernière intervention</h3>
                  <p class="text-center mb-6 text-gray-700 font-serif">
                      Retrouvez Koffy sur les ondes, en podcast ou en chronique audio.
                  </p>
                  
                  {/* Intégration du podcast (remplacez l'iframe par celui d'Ausha)*/}
                  <div class="max-w-lg mx-auto">
                  <iframe
                      title="Ausha Podcast Player"
                      name="Ausha Podcast Player"
                      loading="lazy"
                      id="ausha-3ILa"
                      height="420"
                      style={{ border: 'medium', width: '100%', height: '421px' }}
                      src="https://player.ausha.co/?showId=pb7znHjxlqxy&amp;color=%23a08053&amp;multishow=true&amp;playlist=true&amp;v=3&amp;playerId=ausha-3ILa"
                    ></iframe>
                  </div>
                  
              </div>
          </div>
        </div>

      </div>


      {/* Footer */}
      <footer className="bg-black text-white py-8 mt-12">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
            <div>
              <h3 className="text-lg font-bold mb-4">Liens utiles</h3>
              <ul className="space-y-2">
                <li>
                  <a
                    href="https://koffy.finance/cgu"
                    className="hover:underline text-gray-400 hover:text-white"
                  >
                    Conditions Générales d'Utilisation (CGU)
                  </a>
                </li>
                <li>
                  <a
                    href="https://koffy.finance/cgv"
                    className="hover:underline text-gray-400 hover:text-white"
                  >
                    Conditions Générales de Vente (CGV)
                  </a>
                </li>
                <li>
                  <a
                    href="https://koffy.finance/legal"
                    className="hover:underline text-gray-400 hover:text-white"
                  >
                    Mentions Légales
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-4">Contactez-nous</h3>
              <p>Email : <a href="mailto:support@koffy.com" className="hover:underline text-gray-400 hover:text-white">contact[@]koffy.finance</a></p>
              <p>Téléphone : <a href="tel:+33458105091" className="hover:underline text-gray-400 hover:text-white">+33 4 58 10 50 91</a></p>
              <p>Montreuil FRANCE</p>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-4">Suivez-nous</h3>
              <div className="flex justify-center md:justify-start space-x-4">
                {['https://x.com/koffy_finance', 'https://www.linkedin.com/company/89235713', 'https://www.instagram.com/koffy_finance/'].map((link, i) => (
                  <a key={i} href={link} className="icon bg-white hover:bg-gray-600">
                    <svg className="w-6 h-6 text-black dark:text-white" aria-hidden="true">
                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    </svg>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-8 pt-4 text-center">
            <p>&copy; {new Date().getFullYear()} Koffy. Tous droits réservés.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
